import { Button, Checkbox, Position } from "@blueprintjs/core"
import styled from "@emotion/styled"
import { Form, Formik, Field, FieldProps } from "formik"
import { ReactNode } from "react"

import { useEffect, useState } from "react"
import * as yup from "yup"
import { Input, InputBlock, Text } from "."
import { isEqualValues } from "helpers/object"
import { useCurrentUser } from "features/auth/withAuth"
import MercurySigningToggle from "features/transaction/id/MercurySigningToggle"
import { Tooltip2 } from "@blueprintjs/popover2"

const TallText = styled(Text)`
  height: 95px !important;
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`

const TwoColumnInput = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
`

const SCHEMA = yup.object({
  name: yup
    .string()
    .trim()
    .min(3, "A transaction name must be at least 3 characters.")
    .max(100, "A transaction name must be no more than 100 characters.")
    .required("A transaction name is required"),
  clientName: yup.string().trim().max(60),
  clientNumber: yup
    .string()
    .trim()
    .max(15, "Client number must be no more than 15 characters.")
    .required("Client number is required."),
  matterNumber: yup
    .string()
    .trim()
    .max(15, "Matter number must be no more than 15 characters.")
    .required("Matter number is required"),
  description: yup
    .string()
    .trim()
    .max(350, "Description must be no more than 350 characters."),
})

export type InitialValuesType = {
  name: string
  clientName: string
  clientNumber: string
  matterNumber: string
  description: string
  isDeedTransaction: boolean
  isSample: boolean
}

type TransactionProps = {
  id?: string | null
  name?: string
  clientName?: string
  clientNumber?: string
  matterNumber?: string
  description?: string
  expiredAt?: string | null
  isSample?: boolean
  isDeedTransaction?: boolean
  buttons?: ReactNode
  submitButtonProps?: any
  submitButtonText?: string
  disableWithoutEdit?: boolean
  handleSubmit: (values: any, resetForm: () => void) => void
  hasRedHelperText?: boolean
}

const Transaction = ({
  id = null,
  name = "",
  clientName = "",
  clientNumber = "",
  matterNumber = "",
  description = "",
  expiredAt = null,
  isDeedTransaction = false,
  buttons,
  submitButtonProps = {},
  submitButtonText = "Get started",
  disableWithoutEdit = true,
  handleSubmit,
  hasRedHelperText = false,
  isSample = false,
}: TransactionProps) => {
  const [hasFocused, setHasFocused] = useState(false)
  const { currentUser } = useCurrentUser()

  // NOTE: Probably not the best way to identify the location of this form, but
  // this works for identifying the form in the edit modal vs transaction settings
  // sidebar. CM
  let isInEditModal = hasRedHelperText

  let initialValues: InitialValuesType = {
    name,
    clientName,
    clientNumber,
    matterNumber,
    description,
    isDeedTransaction,
    isSample,
  }

  return (
    <Formik
      initialValues={{
        name,
        clientName,
        clientNumber,
        matterNumber,
        description,
        isDeedTransaction,
        isSample,
      }}
      validationSchema={SCHEMA}
      onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
    >
      {({
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) => {
        const transactionNameHasValidValue = values.name.trim().length > 2

        const handleFocus = () => {
          if (!hasFocused) setHasFocused(true)
        }

        const isExpiredAndLocked = Boolean(expiredAt)

        return (
          <Form>
            {!id && !isInEditModal && !touched.isSample && (
              <IsSampleTrigger
                name={values.name}
                setFieldValue={setFieldValue}
              />
            )}
            <InputBlock
              inverted
              errors={errors}
              touched={touched}
              forId="name"
              label="Transaction name"
              hasRedHelperText={hasRedHelperText}
            >
              <Input
                data-1p-ignore
                large
                fill
                errors={errors}
                touched={touched}
                id="name"
                type="text"
                value={values.name}
                isSample={values.isSample}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                disabled={isExpiredAndLocked}
              />
            </InputBlock>
            <InputBlock
              inverted
              errors={errors}
              touched={touched}
              forId="clientName"
              label="Client name"
              hasRedHelperText={hasRedHelperText}
            >
              <Input
                data-1p-ignore
                fill
                errors={errors}
                touched={touched}
                id="clientName"
                type="text"
                value={values.clientName}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={
                  isExpiredAndLocked ||
                  (!transactionNameHasValidValue && disableWithoutEdit)
                }
              />
            </InputBlock>
            <TwoColumnInput>
              <InputBlock
                inverted
                errors={errors}
                touched={touched}
                forId="clientNumber"
                label="Client number"
                hasRedHelperText={hasRedHelperText}
              >
                <Input
                  data-1p-ignore
                  errors={errors}
                  touched={touched}
                  id="clientNumber"
                  type="text"
                  value={values.clientNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={
                    isExpiredAndLocked ||
                    (!transactionNameHasValidValue && disableWithoutEdit)
                  }
                />
              </InputBlock>
              <InputBlock
                inverted
                errors={errors}
                touched={touched}
                forId="matterNumber"
                label="Matter number"
                hasRedHelperText={hasRedHelperText}
              >
                <Input
                  data-1p-ignore
                  errors={errors}
                  touched={touched}
                  id="matterNumber"
                  type="text"
                  value={values.matterNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={
                    isExpiredAndLocked ||
                    (!transactionNameHasValidValue && disableWithoutEdit)
                  }
                />
              </InputBlock>
            </TwoColumnInput>
            <InputBlock
              inverted
              errors={errors}
              touched={touched}
              forId="description"
              label="Description"
              hasRedHelperText={hasRedHelperText}
            >
              <TallText
                fill
                errors={errors}
                touched={touched}
                id="description"
                type="text"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!transactionNameHasValidValue && disableWithoutEdit}
              />
            </InputBlock>
            <Tooltip2
              content="Please contact customer support to convert to a non-test transaction."
              position={Position.TOP}
              hoverOpenDelay={500}
              // note this logic is purposefully backwards of the Checkbox below... we only want to
              //  show the tooltip when the checkbox is disabled
              disabled={id && isSample ? false : true}
            >
              <Field name="isSample">
                {({ field }: FieldProps) => (
                  <Checkbox
                    id="isSample"
                    className={
                      id && isInEditModal ? "mb-2" : "reverse-checkbox mb-2"
                    }
                    label="Test transaction"
                    checked={field.value}
                    onChange={(evt) => {
                      field.onChange(evt)
                      setFieldTouched("isSample", true)
                    }}
                    disabled={id && isSample ? true : false}
                  />
                )}
              </Field>
            </Tooltip2>
            {id &&
              isInEditModal &&
              Boolean(currentUser?.organizationData?.mercury_signings) && (
                <MercurySigningToggle
                  isChecked={values.isDeedTransaction}
                  isDisabled={isExpiredAndLocked}
                  onChange={handleChange}
                  transactionId={id}
                  theme={hasRedHelperText ? "dark" : "light"}
                />
              )}
            <ButtonWrap>
              {buttons && buttons}
              <Button
                {...submitButtonProps}
                className="ml-3"
                type="submit"
                disabled={
                  isEqualValues(initialValues, values) ||
                  !transactionNameHasValidValue
                }
                loading={isSubmitting}
              >
                {submitButtonText}
              </Button>
            </ButtonWrap>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Transaction

function isTransactionNameTest(str: string) {
  const transactionName = str.toLowerCase()
  const testWords = ["demo", "test", "sample", "training", "example"]
  return testWords.some((testWord) => {
    const regex = new RegExp(`\\b${testWord}\\b`, "i")
    return regex.test(transactionName)
  })
}

function IsSampleTrigger({
  name,
  setFieldValue,
}: {
  name: string
  setFieldValue: (field: string, value: boolean) => void
}) {
  useEffect(() => {
    if (isTransactionNameTest(name)) {
      setFieldValue("isSample", true)
    }
  }, [name, setFieldValue])

  return null
}
